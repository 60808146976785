export const OfferFragment = /* GraphQL */ `
  fragment OfferFields on Offer {
    id
    owner_id
    title
    subtitle
    status
    affiliate_url
    category
    company
    desc
    discord_chan_id
    image
    ok_rebate
    rating
    videos
  }
`
export const OfferSubFragment = /* GraphQL */ `
  fragment OfferSubFields on OfferSub {
    user_id
    offer_id
    active
    targets
    coverage
    random
    use_note
    created_at
    updated_at
    note
  }
`

/*
export const AddOfferInput = /!* GraphQL *!/ `
  input AddOfferInput {
    affiliate_url: String
    category: String
    clicks: ClickCreateNestedManyWithoutOfferInput
    company: String
    conversions: ConversionCreateNestedManyWithoutOfferInput
    created_at: DateTimeISO
    desc: String
    desc_msg: String
    desc_msg2: String
    desc_msg3: String
    discord_chan_id: String
    domain: String
    icp_company: String
    icp_country: String
    icp_desc: String
    icp_education: String
    icp_followers: Int
    icp_industry: String
    icp_title: String
    id: String
    image: String
    industry: String
    message_set: String
    offersubs: OfferSubCreateNestedManyWithoutOfferInput
    ok_rebate: Boolean
    owner: UserCreateNestedOneWithoutOffersInput!
    pricing_url: String
    program_url: String
    provider: AffiliateProvider
    rating: Float
    review: JSON
    reward: String
    reward_model: JSON
    reward_modified: String
    show_promotion: Boolean
    show_store: Boolean
    status: OfferStatus
    subtitle: String
    targets: TargetCreateNestedManyWithoutOfferInput
    terms_url: String
    test: Boolean
    title: String
    type: OfferType
    updated_at: DateTimeISO
    videos: JSON
    website: String
  }
`
*/
