import {OfferFragment} from "./fragments"
import {AddOffer, DeleteOffer, UpdateOffer} from "./mutations"

export const GetOffers = /* GraphQL */ `
  ${OfferFragment}
  query GetOffers {
    offers(where: {status: {in: [active,paused]}}) {
      ...OfferFields
    }
  }
`

export const GetOffer = /* GraphQL */ `
  ${OfferFragment}
  query GetOffer($offerId: String!) {
    findFirstOffer(where: {id: {equals: $offerId, mode: insensitive}}) {
      ...OfferFields
    }
  }
`

export const QUERIES = {
  AddOffer,
  DeleteOffer,
  GetOffer,
  GetOffers,
  UpdateOffer
} as const
export type GraphQLOperations = keyof typeof QUERIES

enum Role {
  ADMIN = "admin",
  USER = "user",
  GUEST = "guest"
}

type RoleRestriction = {
  role: Role
  ownerId?: string // user field for restriction check if needed
}

export const {ADMIN, USER, GUEST} = Role
export const QUERY_ACCESS: Record<GraphQLOperations, RoleRestriction> = {
  AddOffer: {role: USER},
  DeleteOffer: {role: USER, ownerId: "owner_id"},
  GetOffer: {role: GUEST},
  GetOffers: {role: GUEST},
  UpdateOffer: {role: USER, ownerId: "owner_id"},
}

/*
# Reusable Offer Fragment

fragment OfferFields on Offer {
  id
  name
  description
  active
  createdAt
  updatedAt
}

# Get Single Offer
query GetOffer($offerId: String!) {
  findFirstOffer(where: { id: { equals: $offerId } }) {
    ...OfferFields
  }
}

# Get Active Store Offers
query GetActiveStoreOffers($warmerId: String) {
  offers(where: { active: { equals: true }, store: { equals: true }, warmer: { id: { equals: $warmerId } } }) {
    ...OfferFields
  }
}

# Get Active Promotion Offers
query GetActivePromotionOffers {
  offers(where: { active: { equals: true }, promotion: { equals: true } }) {
    ...OfferFields
  }
}

# Get All Offers
query GetOffers {
  offers {
    ...OfferFields
  }
}

# Get Offer Subscriptions
query GetOfferSubs($offerId: String!) {
  offerSubs(where: { offerId: { equals: $offerId } }) {
    id
    active
    templateId
    useNote
    note
  }
}

# Create New Offer
mutation AddOffer($data: OfferInput!) {
  createOffer(data: $data) {
    ...OfferFields
  }
}

# Update Existing Offer
mutation UpdateOffer($id: String!, $data: OfferUpdateInput!) {
  updateOffer(where: { id: $id }, data: $data) {
    ...OfferFields
  }
}

# Delete Offer
mutation DeleteOffer($offerId: String!) {
  deleteOffer(where: { id: $offerId }) {
    id
  }
}

# Get Promos
query GetPromos {
  promos {
    id
    name
    description
  }
}

# Make Promo Claim
mutation MakePromoClaim($code: String!) {
  claimPromo(code: $code) {
    id
    claimed
  }
}

# Get Offer Targets
query GetOfferTargets($offerId: String!) {
  offerTargets(where: { offerId: { equals: $offerId } }) {
    id
    name
    target
  }
}

# Update Offer Targets
mutation UpdateOfferTargets($data: OfferTargetUpdateInput!) {
  updateOfferTargets(data: $data) {
    id
  }
}

# Get Users
query GetUsers {
  users {
    id
    name
    email
    createdAt
  }
}

# Get Single User
query GetUser($id: String!, $profile: Boolean!) {
  findUser(where: { id: $id }) {
    id
    name
    email
    profile @include(if: $profile)
  }
}

# Check if User Exists
query UserExists($email: String!) {
  userExists(email: $email)
}

# Update User
mutation UpdateUser($id: String!, $data: UserUpdateInput!) {
  updateUser(where: { id: $id }, data: $data) {
    id
    name
    email
  }
}

# Get PartnerStack Categories
query GetPartnerStackCategories {
  partnerStackCategories {
    id
    name
  }
}

# Get PartnerStack Industries
query GetPartnerStackIndustries {
  partnerStackIndustries {
    id
    name
  }
}

# Get Preferences
query GetPrefs {
  preferences {
    id
    name
    value
  }
}

# Update Preferences
mutation UpdatePrefs($data: PrefInput!) {
  updatePreferences(data: $data) {
    id
    name
    value
  }
}

# Get Analytics Data for Offers
query GetOfferAnalytics($offerId: String!) {
  analytics(offerId: $offerId) {
    views
    clicks
    conversions
  }
}

# Get Downline Data
query GetDownline {
  downline {
    id
    name
    level
  }
}

# Get Refer Ready Data
query GetReferReady {
  referReady {
    status
    message
  }
}

# Get Click Data
query GetClick($code: String!) {
  click(where: { code: $code }) {
    id
    offer {
      id
      name
    }
  }
}

# Warmlink - PUT
mutation PutWarmlink($offerId: String!) {
  updateWarmlink(offerId: $offerId) {
    id
    name
  }
}

# Warmlink - POST
mutation PostWarmlink($offerId: String!) {
  createWarmlink(offerId: $offerId) {
    id
    name
  }
}

# Get Wrap
query GetWrap($code: String!) {
  wrap(where: { code: $code }) {
    id
    url
    title
  }
}

# Get Wrap with Ad
query GetWrapWithAd($code: String!) {
  wrap(where: { code: $code, ad: { equals: true } }) {
    id
    url
    title
  }
}

# Validate Wrap
query ValidateWrap($code: String!) {
  validateWrap(code: $code) {
    id
    status
  }
}

# Get All Wraps
query GetAllWraps {
  wraps {
    id
    url
    title
  }
}

# Update Wrap
mutation UpdateWrap($id: String!, $data: WrapUpdateInput!) {
  updateWrap(where: { id: $id }, data: $data) {
    id
    title
  }
}

# Delete Wrap
mutation DeleteWrap($code: String!) {
  deleteWrap(where: { code: $code }) {
    id
  }
}

# Verify URL
query VerifyURL($url: String!) {
  verifyURL(url: $url)
}

 */
