// ** React Imports
import { ReactNode, useEffect } from "react"
import {CLOUDFRONT_URL, GA_MEASUREMENT_ID, GA_TAG_ID} from "@wildeye/shared/constants"

// ** Next Imports
import Head from "next/head"
import { useRouter } from "next/router"
import type { NextPage } from "next"
import type { AppProps } from "next/app"
import "@wildeye/frontend/src/configs/i18n"

// ** Store Imports
import { store } from "@wildeye/frontend/src/store"
import { Provider } from "react-redux"

// ** Loader Import
import NProgress from "nprogress"

// ** Emotion Imports
import { CacheProvider } from "@emotion/react"
import type { EmotionCache } from "@emotion/cache"

// ** Config Imports
import { defaultACLObj } from "@wildeye/frontend/src/configs/acl"
import themeConfig from "@wildeye/frontend/src/configs/themeConfig"

// ** Fake-DB Import
import "@wildeye/frontend/src/@fake-db"

// ** Third Party Import
import { Toaster } from "react-hot-toast"
import { LicenseInfo } from "@mui/x-license"

// ** Component Imports
import UserLayout from "@wildeye/frontend/src/layouts/UserLayout"
import ThemeComponent from "@wildeye/frontend/src/@core/theme/ThemeComponent"

// ** Spinner Import
import Spinner from "@wildeye/frontend/src/layouts/components/spinner"

// ** Contexts
import { SettingsConsumer, SettingsProvider } from "@wildeye/frontend/src/@core/context/settingsContext"
import AclGuard from "@wildeye/frontend/src/layouts/components/auth/UserAclGuard"
import AuthGuard from "@wildeye/frontend/src/layouts/components/auth/UserAuthGuard"
import GuestGuard from "@wildeye/frontend/src/layouts/components/auth/UserGuestGuard"
import { SessionProvider } from "next-auth/react"

// ** Styled Components
import ReactHotToast from "@wildeye/frontend/src/@core/styles/libs/react-hot-toast"

// ** Scrape_utils Imports
import { createEmotionCache } from "@wildeye/frontend/src/@core/utils/create-emotion-cache"

// ** Prismjs Styles
import "prismjs"
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/components/prism-jsx"

// ** React Perfect Scrollbar Style
import "react-perfect-scrollbar/dist/css/styles.css"

import "@wildeye/frontend/src/iconify-bundle/icons-bundle-react"

// ** Global css styles
import "@wildeye/frontend/styles/globals.css"
import Script from "next/script"

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: NextPage
  emotionCache: EmotionCache
}

type GuardProps = {
  authGuard: boolean
  guestGuard: boolean
  children: ReactNode
}

const clientSideEmotionCache = createEmotionCache()

const Guard = ({ children, authGuard, guestGuard }: GuardProps) => {
  if (guestGuard) {
    return <GuestGuard fallback={<Spinner />}>{children}</GuestGuard>
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>
  } else {
    return <AuthGuard fallback={<Spinner />}>{children}</AuthGuard>
  }
}

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY as string)

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const router = useRouter() // Correct use of useRouter hook

  useEffect(() => {
    if (typeof window !== "undefined" && themeConfig.routingLoader) {
      // Only run this client-side
      const handleRouteStart = () => NProgress.start()
      const handleRouteComplete = () => NProgress.done()
      const handleRouteError = () => NProgress.done()

      router.events.on("routeChangeStart", handleRouteStart)
      router.events.on("routeChangeComplete", handleRouteComplete)
      router.events.on("routeChangeError", handleRouteError)

      // Cleanup event listeners on unmount
      return () => {
        router.events.off("routeChangeStart", handleRouteStart)
        router.events.off("routeChangeComplete", handleRouteComplete)
        router.events.off("routeChangeError", handleRouteError)
      }
    }
    // Return undefined explicitly if the condition is false
    return undefined
  }, [router.events])

  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  // Variables
  const contentHeightFixed = Component.contentHeightFixed ?? false
  const getLayout =
    Component.getLayout ?? (page => <UserLayout contentHeightFixed={contentHeightFixed}>{page}</UserLayout>)

  const setConfig = Component.setConfig ?? undefined

  const authGuard = Component.authGuard ?? true

  const guestGuard = Component.guestGuard ?? false

  const aclAbilities = Component.acl ?? defaultACLObj

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (typeof window !== "undefined" && window.gtag) {
        window.gtag("config", GA_MEASUREMENT_ID, {
          page_path: url,
          debug_mode: true
        })
      } else {
        console.log(`_app: gtag not available`)
      }
    }
    router.events.on("routeChangeComplete", handleRouteChange)
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events])

  return (
    <>
      {/* Only run Google Analytics in the browser */}
      {typeof window !== "undefined" && (
        <>
          {/* Google Tag Manager Script for the Head */}
          <Script
            id='gtm-head'
            strategy='afterInteractive'
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${GA_TAG_ID}');`
            }}
          />
          <Script
            strategy='afterInteractive'
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
          />
          <Script
            id='gtag-init'
            strategy='afterInteractive'
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${GA_MEASUREMENT_ID}', {
                  page_path: window.location.pathname,
                });
              `
            }}
          />
        </>
      )}
      <Provider store={store}>
        <CacheProvider value={emotionCache}>
          <Head>
            <title>{`${themeConfig.templateName}`}</title>
            <meta name='description' content={`${themeConfig.templateName}`} />
            <meta
              name='keywords'
              content='Affiliate offers, warm introductions, prosperity, community, business, professionals, passive income'
            />
            <meta name='viewport' content='initial-scale=1, width=device-width' />
            <meta property='og:title' content='Warmlink' />
            <meta
              property='og:description'
              content='Warmlink is a global affiliate marketplace and community for for professionals looking for top-rated business products and services, community and significant, free passive income.'
            />
            <meta property='og:url' content='https://warmlink.io' />
            <meta
              property='og:image'
              content={CLOUDFRONT_URL+'/img/warmlink-logo-og-1200x627.jpg'}
            />
            <meta property='og:type' content='website' />
            <meta property='og:site_name' content='Warmlink' />
            <meta property='og:locale' content='en_US' />
            <link rel='icon' href='https://warmlink.io/images/favicon.png' type='image/x-icon' />
          </Head>

          <SessionProvider session={pageProps.session}>
            <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
              <SettingsConsumer>
                {({ settings }) => {
                  return (
                    <ThemeComponent settings={settings}>
                      <Guard authGuard={authGuard} guestGuard={guestGuard}>
                        <AclGuard aclAbilities={aclAbilities} guestGuard={guestGuard} authGuard={authGuard}>
                          {getLayout(<Component {...pageProps} />)}
                        </AclGuard>
                      </Guard>
                      <ReactHotToast>
                        <Toaster position={settings.toastPosition} toastOptions={{ className: "react-hot-toast" }} />
                      </ReactHotToast>
                    </ThemeComponent>
                  )
                }}
              </SettingsConsumer>
            </SettingsProvider>
          </SessionProvider>
        </CacheProvider>
      </Provider>
    </>
  )
}

export default App
