import { AbilityBuilder, Ability } from "@casl/ability"

import { isAdmin, isMember } from "@wildeye/prisma/user-roles"

export type Subjects = string
export type Actions = "manage" | "create" | "read" | "update" | "delete"

export type AppAbility = Ability<[Actions, Subjects]> | undefined

export const AppAbility = Ability as any
export type ACLObj = {
  action: Actions
  subject: string
}

/**
 * Please define your own Ability rules according to your app requirements.
 * We have just shown Admin and Client rules for demo purpose where
 * admin can manage everything and client can just visit ACL page
 */
const defineRulesFor = (role: string, subject: string) => {
  const { can, rules } = new AbilityBuilder(AppAbility)

  if (isAdmin(role)) {
    can(AppAbilityAction.Manage, AppAbilitySubject.All)
  } else if (isMember(role)) {
    can(
      AppAbilityAction.Read,
      [AppAbilitySubject.AclPage, AppAbilitySubject.Invoices])
    can(
      AppAbilityAction.Update,
      [AppAbilitySubject.UserProfile, AppAbilitySubject.AccountSettings])
    can(
      AppAbilityAction.Manage,
      [AppAbilitySubject.Seller, AppAbilitySubject.Warmer]
    )
  } else {
    can(
      [AppAbilityAction.Read, AppAbilityAction.Create, AppAbilityAction.Update, AppAbilityAction.Delete],
      subject)
  }

  return rules
}

export enum AppAbilityAction {
  Manage = "manage",
  Create = "create",
  Read = "read",
  Update = "update",
  Delete = "delete",
  Develop = "develop",
}

export enum AppAbilitySubject {
  All = "all",
  AccountSettings = "account-settings",
  AclPage = "acl-page",
  Chat = "chat",
  FAQ = "faq",
  Inbox = "inbox",
  Invoices = "invoices",
  Notification = "notification",
  Language = "language",
  Pricing = "pricing",
  Warmer = "warmer",
  Search = "search",
  Seller = "seller",
  Shortcuts = "shortcuts",
  UserProfile = "user-profile",
}

export const buildAbilityFor = (role: string, subject: string): AppAbility => {
  return new AppAbility(defineRulesFor(role, subject), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    // @ts-ignore
    detectSubjectType: object => object!.type,
  })
}

export const defaultACLObj: ACLObj = {
  action: "manage",
  subject: "all",
}

export default defineRulesFor
