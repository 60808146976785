// ** Toolkit imports
import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import { graphqlApi, restApi } from "./apps/api/warmlink"

// ** Reducers
import chat from "@wildeye/frontend/src/store/apps/chat"
import user from "@wildeye/frontend/src/store/apps/user"
import email from "@wildeye/frontend/src/store/apps/email"
import invoice from "@wildeye/frontend/src/store/apps/invoice"
import calendar from "@wildeye/frontend/src/store/apps/calendar"
import permissions from "@wildeye/frontend/src/store/apps/permissions"

export const store = configureStore({
  reducer: {
    user,
    chat,
    email,
    invoice,
    calendar,
    permissions,
    [restApi.reducerPath]: restApi.reducer,
    [graphqlApi.reducerPath]: graphqlApi.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
      .concat(restApi.middleware) // RTK Query middleware for REST
      .concat(graphqlApi.middleware), // RTK Query middleware for GraphQL
  devTools: process.env.NODE_ENV !== "production" // Ensure DevTools are enabled in development
})
setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
