/**
 * Config
 * -------------------------------------------------------------------------------------
 * ! IMPORTANT: Make sure you clear the browser local storage in order to see the config changes in the template.
 * ! To clear local storage, you may refer https://www.leadshook.com/help/how-to-clear-local-storage-in-google-chrome-browser/.
 */

// ** MUI Imports
import { Direction } from "@mui/material"

// ** Types
import {
  Skin,
  Mode,
  AppBar,
  Footer,
  ContentWidth,
  VerticalNavToggle,
  HorizontalMenuToggle
} from "@wildeye/frontend/src/@core/layouts/types"

type ThemeConfig = {
  skin: Skin
  mode: Mode
  appBar: AppBar
  footer: Footer
  navHidden: boolean
  appBarBlur: boolean
  direction: Direction
  templateName: string
  navCollapsed: boolean
  routingLoader: boolean
  disableRipple: boolean
  navigationSize: number
  navSubItemIcon: string
  menuTextTruncate: boolean
  contentWidth: ContentWidth
  disableCustomizer: boolean
  responsiveFontSizes: boolean
  collapsedNavigationSize: number
  horizontalMenuAnimation: boolean
  layout: "vertical" | "horizontal"
  verticalNavToggleType: VerticalNavToggle
  horizontalMenuToggle: HorizontalMenuToggle
  afterVerticalNavMenuContentPosition: "fixed" | "static"
  beforeVerticalNavMenuContentPosition: "fixed" | "static"
  toastPosition: "top-left" | "top-center" | "top-right" | "bottom-left" | "bottom-center" | "bottom-right"
  logo: string,
  logoWidth: number
}

const themeConfig: ThemeConfig = {
  // ** Layout Configs
  templateName: "Warmlink" /* App Name */,
  layout: "horizontal" /* vertical | horizontal */,
  mode: "light" as Mode /* light | dark | semi-dark /*! Note: semi-dark value will only work for Vertical Layout */,
  direction: "ltr" /* ltr | rtl */,
  skin: "default" /* default | bordered */,
  contentWidth: "full" /* full | boxed */,
  footer: "static" /* fixed | static | hidden */,
  logo: "/images/warmlink.svg",
  logoWidth: 175,

  // ** Routing Configs
  routingLoader: true /* true | false */,

  // ** Navigation (Menu) Configs
  navHidden: false /* true | false */,
  menuTextTruncate: false /* true | false */,
  navSubItemIcon: "mdi:circle-outline" /* Icon */,
  verticalNavToggleType: "accordion" /* accordion | collapse /*! Note: This is for Vertical navigation menu only */,
  navCollapsed: false /* true | false /*! Note: This is for Vertical navigation menu only */,
  navigationSize: 350 /* Number in px(Pixels) /*! Note: This is for Vertical navigation menu only */,
  collapsedNavigationSize: 68 /* Number in px(Pixels) /*! Note: This is for Vertical navigation menu only */,
  afterVerticalNavMenuContentPosition: "fixed" /* fixed | static */,
  beforeVerticalNavMenuContentPosition: "fixed" /* fixed | static */,
  horizontalMenuToggle: "hover" /* click | hover /*! Note: This is for Horizontal navigation menu only */,
  horizontalMenuAnimation: true /* true | false */,

  // ** AppBar Configs
  appBar: "fixed" /* fixed | static | hidden /*! Note: hidden value will only work for Vertical Layout */,
  appBarBlur: false /* true | false */,

  // ** Other Configs
  responsiveFontSizes: true /* true | false */,
  disableRipple: false /* true | false */,
  disableCustomizer: false /* true | false */, // FIXME later: After rev 09c4ef43 I broke the cusomizer (shos up in wide admin)
  toastPosition: "bottom-left" /* top-left | top-center | top-right | bottom-left | bottom-center | bottom-right */
}

export default themeConfig
