import {AuthorityLevel, CookieValue, PromoClaim, MessageVars} from "./types"
import {v4 as uuidv4} from 'uuid'
import {AUTHORITY_LEVELS, AUTHORITY_SUBLEVELS} from "./constants"

export * from "./types"
export * from "./constants"
export * from "./states"
export * from "./links"
export * from "./regex"

export const isEmpty = (obj: any): boolean => Object.keys(obj).length === 0

export const isVercel = (): boolean => process.env.VERCEL === '1'

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development'

export const isProduction = (): boolean => process.env.NODE_ENV === 'production' && !isPreview()

export const isPreview = (): boolean => process.env.NEXT_PUBLIC_APP?.includes('preview') || false

export function logenv(only_next_public: boolean = false) {
  console.log(`>>> environment vars:`)
  for (const key in process.env) {
    if (!only_next_public || key.startsWith('NEXT_PUBLIC_')) {
      console.log(`${key}: ${process.env[key]}`)
    }
  }
}

// noinspection JSUnusedGlobalSymbols
export function logenvStr(only_next_public: boolean = false): string {
  let str = ''
  for (const key in process.env) {
    if (!only_next_public || key.startsWith('NEXT_PUBLIC_')) {
      str += `${key}: ${process.env[key]}\n`
    }
  }
  return str
}

export const prependWWWLinkedInProfileURL = (url: string): string => url.replace("//linkedin", "//www.linkedin")


export function parseBoolean(str: string | null): boolean | undefined {
  switch (str) {
    case "true":
      return true
    case "false":
      return false
  }
  return undefined
}

export const sleep = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms))

export function replicateList<T>(inputList: T[], desiredLen: number): T[] {
  let replicatedList: T[] = []
  if (inputList.length == 0)
    return []
  while (replicatedList.length < desiredLen) {
    replicatedList = replicatedList.concat(JSON.parse(JSON.stringify(inputList)))
  }
  return replicatedList.slice(0, desiredLen)
}

export function replicateListUniqueId<T extends { id: string }>(inputList: T[], desiredLen: number): T[] {
  let replicatedList: T[] = []
  if (inputList.length === 0) return []
  while (replicatedList.length < desiredLen) {
    const newBatch = inputList.map(item => {
      return {...item, id: uuidv4()}
    })
    replicatedList = replicatedList.concat(newBatch)
  }
  return replicatedList.slice(0, desiredLen)
}

export function isPreviousDay(date: Date): boolean {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const inputDate = new Date(date)
  inputDate.setHours(0, 0, 0, 0)
  return inputDate < today
}

export function durationStr(ms: number) {
  const milliseconds = ms % 1000
  const seconds = Math.floor((ms / 1000) % 60)
  const minutes = Math.floor((ms / (1000 * 60)) % 60)
  const hours = Math.floor((ms / (1000 * 60 * 60)) % 24)
  const days = Math.floor(ms / (1000 * 60 * 60 * 24))
  return `${days}d ${hours}h ${minutes}m ${seconds}s ${milliseconds}ms`
}

export const clamp = (value: number, min: number, max: number): number => Math.max(min, Math.min(max, value))

/**
 * Authority encoding is currently [0..2]*100 + [0..2] see constants AUTHORITY_LEVELS and AUTHORITY_SUBLEVELS
 */
export function clampAuthorityEncoding(level: number): number {
  const prime = clamp(Math.round(level / 100), 0, AUTHORITY_LEVELS - 1)
  const sub = clamp(level - Math.round(level / 100) * 100, 0, AUTHORITY_SUBLEVELS - 1)
  return prime * 100 + sub
}

export const encodeAuthorityLevel = ({prime, sub}: AuthorityLevel): number => clampAuthorityEncoding(prime * 100 + sub)

export const decodeAuthorityLevel = (level: number): AuthorityLevel =>
  ({prime: Math.round(level / 100), sub: level - Math.round(level / 100)})

export function fillMessageTemplate(vars: Partial<MessageVars>, template: string, itemize: boolean = false): string | null {
  return template ? replacePlaceholders(template, vars, itemize) : null
}

function htmlEscape(str: string): string {
  return str.replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;")
}

function replacePlaceholders(msg: string, vars: Partial<MessageVars>, itemize: boolean = false): string {
  const proxy = new Proxy(vars, {
    get: (target, prop) => {
      return prop in target ? String(target[prop as keyof typeof target]) : ""
    }
  })

  return msg.replace(/@(\w+)/g, (_match, key) => {
    const value = htmlEscape(proxy[key as keyof typeof proxy] as string)
    return itemize ? (value ? `<wl_${key}>${value}</wl_${key}>` : '') : value
  }).replace(/(\n\s*){3,}/g, '\n\n')
    .replace(/[\f\t\v\u0020\u00a0\u1680\u2000-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff]+/g, ' ')
    .trim()
}

/**
 * Produce trailing message string with a mission statement and optout link.
 * @param {string} mission
 * @param {string} optout_url
 */
export const makeTrailer = (mission: string, optout_url: string): string => `${mission || ''} Unsubscribe at ${optout_url}`

export function getInitials(name: string): string {
  const nameArray = name.split(' ')
  const initials = nameArray
    .map((n: string) => n[0])
    .filter((char) => /^[A-Za-z]+$/.test(char))
    .join('')
  return initials.toUpperCase()
}

export const sanitizeForPrisma = (input: string): string => input.replace(/\\u0000/g, '')

export const sanitizeJSONForPrisma = (input: object): object => JSON.parse(JSON.stringify(input).replace(/\\u0000/g, ''))

export const singleLine = (s: string): string => s
  .replace(/\n{3,}/g, '\n\n')
  .replace(/(?<!\n)\n(?!\n)/g, ' ')
  .replace(/[^\S\n]+/g, ' ')
  .replace(/\n\n\s+/g, '\n\n')
  .trim()


export function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const getAllEnvVars = (): { key: string, value: string | undefined }[] =>
  Object.entries(process.env)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .map(([key, value]) => ({key, value}))

export function serializeCookie(name: string, value: string, options: any = {}) {
  const optList = [];
  if (options.maxAge) optList.push(`Max-Age=${options.maxAge}`)
  if (options.domain) optList.push(`Domain=${options.domain}`)
  if (options.path) optList.push(`Path=${options.path}`)
  if (options.expires) optList.push(`Expires=${options.expires.toUTCString()}`)
  if (options.httpOnly) optList.push(`HttpOnly`)
  if (options.secure) optList.push(`Secure`)
  if (options.sameSite) optList.push(`SameSite=${options.sameSite}`)
  return `${name}=${value}; ${optList.join('; ')}`
}

export function newCookieValue(value: any): string {
  return JSON.stringify(<CookieValue> {
    value: value,
    timestamp: new Date().toISOString()
  })
}

export function isPromoExpired(promo: PromoClaim): boolean {
  return new Date() >= new Date(promo.expires)
}

export function isPromotionExpiring(expires: string): boolean {
  const daysDiff = (new Date(expires).getTime() - new Date().getTime()) / (1000 * 3600 * 24)
  return daysDiff >= 0 && daysDiff <= 30
}

export function isPromoExpiring(promo: PromoClaim): boolean {
  return isPromotionExpiring(promo.expires)
}

export function trimObjectValues<T extends Record<string, any>>(obj: T): T {
  const trimmedObj: T = { ...obj }
  for (const key in trimmedObj) {
    if (typeof trimmedObj[key] === 'string') {
      trimmedObj[key] = (trimmedObj[key] as string).trim() as any
    }
  }
  return trimmedObj
}

