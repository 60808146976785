import i18n from "i18next"
import Backend from "i18next-http-backend"
import {initReactI18next} from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

const isBrowser = typeof window !== 'undefined'

if (!i18n.isInitialized && isBrowser) {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      lng: "en",
      backend: {
        loadPath: "/locales/{{lng}}.json",
        // loadPath: isBrowser
        //   ? '/locales/{{lng}}.json'  // Client-side
        //   : `${process.env.NEXT_PUBLIC_APP}/locales/{{lng}}.json`,  // Server-side
      },
      fallbackLng: "en",
      debug: process.env.NODE_ENV === 'development',
      detection: {
        order: ['querystring', 'cookie', 'navigator', 'header', 'localStorage'],
        lookupQuerystring: 'lng',
        caches: [] // Disable caching temporarily
      },
      keySeparator: false,
      react: {
        useSuspense: false,
      },
      interpolation: {
        escapeValue: false,
      },
    })

  i18n.on('languageChanged', (lng) => {
    console.log('Detected language:', lng)
  })
  i18n.on('loaded', (loaded) => {
    console.log('Loaded translations:', loaded)
  })
}

export default i18n
