export const AddOffer = /* GraphQL */ `
  mutation AddOffer($data: OfferCreateInput!) {
    createOneOffer(data: $data) {
      ...OfferFields
    }
  }
`

export const UpdateOffer = /* GraphQL */ `
  mutation UpdateOffer($offerId: String!, $data: OfferUpdateInput!) {
    updateOneOffer(where: { id: $offerId }, data: $data) {
      ...OfferFields
    }
  }
`

export const DeleteOffer = /* GraphQL */ `
  mutation DeleteOffer($offerId: String!) {
    deleteOneOffer(where: { id: $offerId }) {
      id
    }
  }
`
